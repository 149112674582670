
import React, { useEffect, useState, useCallback } from "react"
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import styles from '../../assets/scss/admin/index.module.scss'
import firebase from '../../utils/firebase'
import CircularProgress from "@material-ui/core/CircularProgress"
import Button from '@material-ui/core/Button';
import { format, addYears } from 'date-fns'
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"

export default (props) => {
  const now = new Date()
  const [events, setEvents] = useState(null);
  const [searchYear, setSearchYear] = useState(format(now, 'yyyy'));
  const [searchMonth, setSearchMonth] = useState(format(now, 'MM'));

  const fetchCalendarEvents = useCallback(() => {
    const functions = firebase.functions();
    functions.httpsCallable("getCalendars")({
      year: searchYear,
      month: searchMonth,
      filter: '[予約可]|[フリー]|[有料]|[コア]|[管理]'
    }).then(res => {
      setEvents(res.data)
    }).catch(e => {
      console.log(e);
    })
  }, [searchMonth, searchYear])

  // componentDidMount
  useEffect(() => {
    fetchCalendarEvents()
  }, [fetchCalendarEvents]);

  const handleSearch = () => {
    setEvents(null)
    fetchCalendarEvents()
  }

  return (
    <React.Fragment>

      <div className={styles.search}>
        <FormControl
          className={styles.searchForm}
        >
          <InputLabel id="prefecture-label">Search Year</InputLabel>
          <Select
            value={searchYear}
            onChange={ (e) => { setSearchYear(e.target.value);  } }
            inputProps={{
              name: 'searchYear',
              id: 'searchYear',
            }}
          >
            { ( () => {
              var yearArray = []
              for( let i = 0; i <= 3; i++ ){
                let y = addYears(now, i);
                let yStr = format(y, 'yyyy')
                yearArray.push(<MenuItem key={i} value={yStr}>{ yStr }</MenuItem>)
              }
              return yearArray
            } )() }
          </Select>
        </FormControl>

        <FormControl
          className={styles.searchForm}
        >
          <InputLabel id="prefecture-label">Search Month</InputLabel>
          <Select
            value={searchMonth}
            onChange={ (e) => { setSearchMonth(e.target.value);  } }
            inputProps={{
              name: 'searchMonth',
              id: 'searchMonth',
            }}
          >
            { ( () => {
              return [1,2,3,4,5,6,7,8,9,10,11,12].map((m) => {
                let mStr = ( '00' + m ).slice( -2 );
                return <MenuItem key={m} value={mStr}>{ mStr }</MenuItem>
              })
            } )() }
          </Select>
        </FormControl>

        <Button
          color="primary"
          variant="contained"
          onClick={ handleSearch }
        >
          Search
        </Button>
        <div className={styles.grow} />
        <Button
          color="primary"
          variant="contained"
          onClick={() => {window.open("https://calendar.google.com/calendar?cid=bW40djE3YjNvNXZrb3ExMDJwOGhzamRzNjhAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ")}}
        >
          Google Calendar
        </Button>
      </div>

      <div className={styles.tableDesc}>
        イベント管理はGoogleCalendarで編集してください。<br />
        [予約可]：全会員種別で閲覧可能<br />
        [フリー]：フリー、有料、コア会員種別で閲覧可能<br />
        [有料]：有料、コア会員種別で閲覧可能<br />
        [コア]：コア会員種別で閲覧可能<br />
      </div>

      <TableContainer component={Paper}>
        <Table size="small" aria-label="users">
          <TableHead>
            <TableRow>
              <TableCell align="left">Event Summary</TableCell>
              <TableCell align="left">Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { ( () => {
              if( !events ) {
                return <TableRow className={styles.root}>
                  <TableCell align="center" colSpan={2} style={{ padding: '20px' }}>
                    <CircularProgress/>
                  </TableCell>
                </TableRow>
              } else if( events.length === 0 ){
                return <TableRow className={styles.root}>
                  <TableCell align="center" colSpan={2} style={{ padding: '20px' }}>
                    該当するイベントはありません。
                  </TableCell>
                </TableRow>
              } else
                return events.map((user, index) => {
                  return <TableRow className={styles.root} key={index}>
                    <TableCell align="left">{ user.start.date || user.start.dateTime }</TableCell>
                    <TableCell align="left">{ user.summary }</TableCell>
                  </TableRow>
                })
            } )() }
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  )
}