import React, { useEffect } from "react"
import Layout from "../../components/admin/Layout"
import Events from "../../components/admin/Events"

const Calendar = props => {
  // componentDidMount
  useEffect(() => {
  }, []);

  return (
    <div>
      <Layout>
        <Events />
      </Layout>
    </div>
  )
}

export default Calendar
